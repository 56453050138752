.invoiceupload {
  margin-bottom: 5px;
}

#invoicemessage {
  padding: 0.375rem 0.75rem;
}

.documentupload {
  margin-bottom: 5px;
}

.uploadprogressbars {
  margin-bottom: 5px;
}

.multiupload {
  margin-bottom: 10px;
}

.rota {
  height: 600px;
}

.popupheader {
  display: flex !important;
}

.importtitle {
  font-size: x-small;
}

.rbc-events-container {
  font-size: 90%;
}

.rbc-allday-cell {
  display: none;
}
.rbc-time-view .rbc-header {
  border-bottom: none;
}

.recurrentrow {
  margin-top: 10px;
  margin-bottom: 10px;
}
.swatch {
  padding: '5px';
  background: '#fff';
  border-radius: '1px';
  box-shadow: '0 0 0 1px rgba(0,0,0,.1)';
  display: 'inline-block';
  cursor: 'pointer';
}


.rbc-timeslot-group {
  border-bottom: 1px solid #b5b2b2;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #c2c0c0;
}

.rbc-day-slot .rbc-time-slot {
  border-top-width: 1px;
  border-top-color: #bab6b6;
}

.checks {
    opacity: 1;
    position: absolute;
    // visibility: hidden; 
}

.checklabel {
  vertical-align: 'top' !important;
 padding-left: 10px !important;
 line-height: 20px !important;
}

.rbc-current-time-indicator {
  display: none;
}

.error-row {
  background-color: #edabab;
}